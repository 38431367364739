type formInput = Record<string, string>;

const formInputs: formInput[] = [
  {
    nameInput: 'name',
    label: 'form_vacancy.name',
    icon: '/case/restocare/contact_name.svg',
  },
  {
    nameInput: 'phone',
    icon: '/case/restocare/contact_phone.svg',
  },
  {
    nameInput: 'email',
    label: 'form_vacancy.email',
    icon: '/case/restocare/contact_email.svg',
  },
];

export default formInputs;
