import { getGa, getUtmData } from '../../directions/restocare/utils/ga';
import { sendVacancyRequest } from '../../../utils/api';

export const sendVacancyFeedback = ({
  email,
  phone,
  name,
  captcha,
  file,
  filename,
}: Record<string, any>) => {
  const referrer = document?.referrer || '';
  const googleId = getGa();
  const utm = getUtmData();

  return sendVacancyRequest({
    email,
    phone,
    name,
    file,
    filename,
    captcha,
    ...utm,
    googleId,
    referrer,
  });
};
