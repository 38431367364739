import React, { FC, useCallback, useMemo, useState } from 'react';
import { graphql, PageProps } from 'gatsby';
import { IPositionsQuery } from '../../queries/positions/types';
import { MainLayout } from '../MainLayout';
import { VacancyPageContent } from '../../components/vacancies/VacancyPageContent';
import { IHeaderQuery } from '../../queries/headers/types';
import { VacancySuccessModal } from '../../components/vacancies/VacancySuccessModal';
import { ReCaptchaProvider } from '../../components/common/ReCaptchaProvider';

type IProps = PageProps<
  IPositionsQuery & IHeaderQuery,
  { locale: string; slug: string }
>;

const VacancyPage: FC<IProps> = ({ data, pageContext: { locale, slug } }) => {
  const [isModalShown, setIsModalShown] = useState(false);

  const position = useMemo(
    () => data.positions.edges.find((edge) => edge.node),
    [data.positions]
  );

  const title = useMemo(
    () =>
      position?.node.frontmatter.title ||
      position?.node.frontmatter.business ||
      'vacancies.title',
    [position]
  );

  const header = useMemo(
    () => data.headers.edges.find((edge) => edge.node),
    [data.headers]
  );

  if (!position) return null;

  const contacts = header?.node.frontmatter?.contacts || {};

  const showSuccess = useCallback(
    () => setIsModalShown(true),
    [setIsModalShown]
  );
  const hideSuccess = useCallback(
    () => setIsModalShown(false),
    [setIsModalShown]
  );
  return (
    <ReCaptchaProvider>
      <MainLayout title={title} locale={locale}>
        {isModalShown && <VacancySuccessModal onClose={hideSuccess} />}

        <VacancyPageContent
          showSuccess={showSuccess}
          contacts={contacts}
          position={position.node}
        />
      </MainLayout>
    </ReCaptchaProvider>
  );
};

export const query = graphql`
  query ($slug: String!) {
    positions: allMarkdownRemark(filter: { fileAbsolutePath: { eq: $slug } }) {
      ...PositionsFields
    }

    headers: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/vacancies/header/header/" } }
    ) {
      ...HeaderFields
    }
  }
`;

export default VacancyPage;
